<template>
    <div class="router-view">
        <!-- Router view -->
        <div class="router-view-in-app" id="modalZone">
            <router-view style="position:relative"></router-view>
        </div>
    </div>
</template>


<script>

export default {
    mounted(){
        console.log("[In app] Current user ", this.getCurrentUser)
    }
}
   
</script>

<style>

body,html{
    background-color: var(--color_background) !important;
}

.router-view{
    background-color: var(--color_background);
}

.router-view-in-app{
    /*
    position: relative;
    height: 100%;
    left: 0px;
    width: 100%;
    */
    background-color: var(--color_background);
}


</style>

<style src="../styles/verticalBanner.css" ></style>